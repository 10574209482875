import { default as AiGeneratedChecked } from './ai-generated-checked'
import { default as AiGeneratedFlag } from './ai-generated-flag'
import { default as AiGenerated } from './ai-generated'
import { default as AlertInfoBig } from './alert-info-big'
import { default as AlertInfoSmall } from './alert-info-small'
import { default as AlertSuccessBig } from './alert-success-big'
import { default as AlertSuccessSmall } from './alert-success-small'
import { default as AlertWarningBig } from './alert-warning-big'
import { default as AlertWarningSmall } from './alert-warning-small'
import { default as Archive1 } from './archive-1'
import { default as Archive } from './archive'
import { default as ArrowAlias } from './arrow-alias'
import { default as ArrowBendedBottomRight } from './arrow-bended-bottom-right'
import { default as ArrowBendedLeftUp } from './arrow-bended-left-up'
import { default as ArrowBendedUpLeft } from './arrow-bended-up-left'
import { default as ArrowDown } from './arrow-down'
import { default as ArrowLeft } from './arrow-left'
import { default as ArrowRight } from './arrow-right'
import { default as ArrowUp } from './arrow-up'
import { default as Backlink } from './backlink'
import { default as BoldItalic } from './bold-italic'
import { default as Bold } from './bold'
import { default as BrandApple } from './brand-apple'
import { default as BrandChrome } from './brand-chrome'
import { default as BrandFacebook } from './brand-facebook'
import { default as BrandFigma } from './brand-figma'
import { default as BrandFront } from './brand-front'
import { default as BrandLinear } from './brand-linear'
import { default as BrandLinkedin } from './brand-linkedin'
import { default as BrandLinux } from './brand-linux'
import { default as BrandLoom } from './brand-loom'
import { default as BrandSpecific } from './brand-specific'
import { default as BrandTwitter } from './brand-twitter'
import { default as BrandWindows } from './brand-windows'
import { default as BurgerBigTiny } from './burger-big-tiny'
import { default as BurgerBig } from './burger-big'
import { default as BurgerSlim2 } from './burger-slim-2'
import { default as BurgerSlim } from './burger-slim'
import { default as BurgerTopbarWindows } from './burger-topbar-windows'
import { default as Caption } from './caption'
import { default as CaseSensitive } from './case-sensitive'
import { default as CategoryDot } from './category-dot'
import { default as ChatGptInverted } from './chat-gpt-inverted'
import { default as ChatGpt } from './chat-gpt'
import { default as CheckCircleInverted } from './check-circle-inverted'
import { default as CheckCircle } from './check-circle'
import { default as CheckboxTick } from './checkbox-tick'
import { default as ChevronBigLeft } from './chevron-big-left'
import { default as ChevronBigRight } from './chevron-big-right'
import { default as ChevronSmallDoubleDown } from './chevron-small-double-down'
import { default as ChevronSmallDoubleLeft } from './chevron-small-double-left'
import { default as ChevronSmallDoubleRight } from './chevron-small-double-right'
import { default as ChevronSmallDoubleUp } from './chevron-small-double-up'
import { default as ChevronSmallDownBold2 } from './chevron-small-down-bold-2'
import { default as ChevronSmallDownBold } from './chevron-small-down-bold'
import { default as ChevronSmallDown } from './chevron-small-down'
import { default as ChevronSmallLeft } from './chevron-small-left'
import { default as ChevronSmallRight } from './chevron-small-right'
import { default as ChevronSmallUpBold2 } from './chevron-small-up-bold-2'
import { default as ChevronSmallUpBold } from './chevron-small-up-bold'
import { default as ChevronSmallUp } from './chevron-small-up'
import { default as ClearStyling } from './clear-styling'
import { default as ClearTiny } from './clear-tiny'
import { default as Clear } from './clear'
import { default as CloseBig } from './close-big'
import { default as CloseTab } from './close-tab'
import { default as Close } from './close'
import { default as CloudDownload } from './cloud-download'
import { default as CloudOffline2 } from './cloud-offline-2'
import { default as CloudOfflineInverted } from './cloud-offline-inverted'
import { default as CloudOffline } from './cloud-offline'
import { default as CloudTick2 } from './cloud-tick-2'
import { default as CloudTick } from './cloud-tick'
import { default as CloudUpload } from './cloud-upload'
import { default as CodeBlock } from './code-block'
import { default as CollapseBigDown } from './collapse-big-down'
import { default as CollapseBigRight } from './collapse-big-right'
import { default as CollapseDown } from './collapse-down'
import { default as CollapseRight } from './collapse-right'
import { default as CollapseTinyDown } from './collapse-tiny-down'
import { default as CollapseUp } from './collapse-up'
import { default as CommentCountMulti } from './comment-count-multi'
import { default as CommentCountSingle } from './comment-count-single'
import { default as CommentCountTwo } from './comment-count-two'
import { default as CommentInverted } from './comment-inverted'
import { default as Comment } from './comment'
import { default as CommentsPanel } from './comments-panel'
import { default as Company } from './company'
import { default as CopyToClipboard } from './copy-to-clipboard'
import { default as DescriptionForAi } from './description-for-ai'
import { default as DiscussionAccept } from './discussion-accept'
import { default as DiscussionReject } from './discussion-reject'
import { default as DiscussionResolve } from './discussion-resolve'
import { default as Download1 } from './download-1'
import { default as Download2 } from './download-2'
import { default as Drag } from './drag'
import { default as DropdownDown } from './dropdown-down'
import { default as DropdownRight } from './dropdown-right'
import { default as DropdownSelectBox } from './dropdown-select-box'
import { default as EmbedOpenInBrowser } from './embed-open-in-browser'
import { default as EmbedPlay } from './embed-play'
import { default as EmbedZoomIn } from './embed-zoom-in'
import { default as ErrorExclamation } from './error-exclamation'
import { default as ExclamationCircle } from './exclamation-circle'
import { default as ExclamationTriangleInverted } from './exclamation-triangle-inverted'
import { default as ExclamationTriangle } from './exclamation-triangle'
import { default as Export } from './export'
import { default as ExternalUrl } from './external-url'
import { default as EyeCrossed } from './eye-crossed'
import { default as FeatureLink } from './feature-link'
import { default as FilesCustomers } from './files-customers'
import { default as FilesDashboard } from './files-dashboard'
import { default as FilesEvents } from './files-events'
import { default as FilesFields } from './files-fields'
import { default as FilesGettingStarted } from './files-getting-started'
import { default as FilesImportData } from './files-import-data'
import { default as FilesInbox } from './files-inbox'
import { default as FilesInputRequestV2 } from './files-input-request-v2'
import { default as FilesInputRequest } from './files-input-request'
import { default as FilesIntegrationsPuzzle } from './files-integrations-puzzle'
import { default as FilesNotificationsInbox } from './files-notifications-inbox'
import { default as FilesOverview } from './files-overview'
import { default as FilesProblemsV2 } from './files-problems-v2'
import { default as FilesProblems } from './files-problems'
import { default as FilesReviewRequestsV2 } from './files-review-requests-v2'
import { default as FilesReviewRequests } from './files-review-requests'
import { default as FilesRoadmap } from './files-roadmap'
import { default as FilesSourcesInverted } from './files-sources-inverted'
import { default as FilesSources } from './files-sources'
import { default as FilesStudies } from './files-studies'
import { default as FilesSurveyInverted } from './files-survey-inverted'
import { default as FilesSurveyWidget } from './files-survey-widget'
import { default as FilesSurvey } from './files-survey'
import { default as FilesTasks } from './files-tasks'
import { default as FilesTopics } from './files-topics'
import { default as FilesUserInsightsV2 } from './files-user-insights-v2'
import { default as FilesUserInsights } from './files-user-insights'
import { default as Filter } from './filter'
import { default as FolderAliasInverted } from './folder-alias-inverted'
import { default as FolderAlias } from './folder-alias'
import { default as FolderBookmarksInverted } from './folder-bookmarks-inverted'
import { default as FolderBookmarks } from './folder-bookmarks'
import { default as FolderDraftsInverted } from './folder-drafts-inverted'
import { default as FolderDrafts } from './folder-drafts'
import { default as FolderInverted } from './folder-inverted'
import { default as FolderLockedInverted } from './folder-locked-inverted'
import { default as FolderLocked } from './folder-locked'
import { default as FolderMissingParentInverted } from './folder-missing-parent-inverted'
import { default as FolderMissingParent } from './folder-missing-parent'
import { default as FolderSharedAliasInverted } from './folder-shared-alias-inverted'
import { default as FolderSharedAlias } from './folder-shared-alias'
import { default as FolderSharedInverted } from './folder-shared-inverted'
import { default as FolderSharedLockedAliasInverted } from './folder-shared-locked-alias-inverted'
import { default as FolderSharedLockedAlias } from './folder-shared-locked-alias'
import { default as FolderSharedLockedInverted } from './folder-shared-locked-inverted'
import { default as FolderSharedLocked } from './folder-shared-locked'
import { default as FolderShared } from './folder-shared'
import { default as FolderSmartInverted } from './folder-smart-inverted'
import { default as FolderSmart } from './folder-smart'
import { default as FolderSpecsInverted } from './folder-specs-inverted'
import { default as FolderSpecs } from './folder-specs'
import { default as Folder } from './folder'
import { default as Formatting } from './formatting'
import { default as FullScreen } from './full-screen'
import { default as Gear } from './gear'
import { default as Globe1 } from './globe-1'
import { default as GlobeLocked } from './globe-locked'
import { default as Globe } from './globe'
import { default as Hashtag } from './hashtag'
import { default as Heading } from './heading'
import { default as HelpPanel2 } from './help-panel-2'
import { default as HelpPanelInverted } from './help-panel-inverted'
import { default as HelpPanel } from './help-panel'
import { default as HiddenComment } from './hidden-comment'
import { default as HidePanelArrowLeft } from './hide-panel-arrow-left'
import { default as HidePanelArrowRight } from './hide-panel-arrow-right'
import { default as Highlight } from './highlight'
import { default as HorizontalRule } from './horizontal-rule'
import { default as Image } from './image'
import { default as Import } from './import'
import { default as InfoCircle } from './info-circle'
import { default as Info } from './info'
import { default as InlineCode } from './inline-code'
import { default as InputFieldError } from './input-field-error'
import { default as InputFieldSuccess } from './input-field-success'
import { default as InsightAll } from './insight-all'
import { default as InsightAssigned } from './insight-assigned'
import { default as InsightInverted } from './insight-inverted'
import { default as InsightUnassigned } from './insight-unassigned'
import { default as InsightUnprocessed } from './insight-unprocessed'
import { default as Insight } from './insight'
import { default as Italic } from './italic'
import { default as ItemFeatureCounter } from './item-feature-counter'
import { default as ItemFeatureInverted } from './item-feature-inverted'
import { default as ItemFeatureSetInverted } from './item-feature-set-inverted'
import { default as ItemFeatureSet } from './item-feature-set'
import { default as ItemFeatureTemplateV2 } from './item-feature-template-v2'
import { default as ItemFeatureTemplate } from './item-feature-template'
import { default as ItemFeature } from './item-feature'
import { default as ItemProblemInverted } from './item-problem-inverted'
import { default as ItemProblemV2 } from './item-problem-v2'
import { default as ItemProblem } from './item-problem'
import { default as ItemProjectCounter } from './item-project-counter'
import { default as ItemProjectInverted } from './item-project-inverted'
import { default as ItemProject } from './item-project'
import { default as ItemTagAdd } from './item-tag-add'
import { default as ItemTagInverted } from './item-tag-inverted'
import { default as ItemTag } from './item-tag'
import { default as KebabBig } from './kebab-big'
import { default as KebabSmall } from './kebab-small'
import { default as LeadUnassigned } from './lead-unassigned'
import { default as LinkBold } from './link-bold'
import { default as Link } from './link'
import { default as ListBullet } from './list-bullet'
import { default as ListCheck } from './list-check'
import { default as ListIndentLeft } from './list-indent-left'
import { default as ListIndentRight } from './list-indent-right'
import { default as ListOrdered } from './list-ordered'
import { default as ListUnordered } from './list-unordered'
import { default as List } from './list'
import { default as LoaderCircleTiny } from './loader-circle-tiny'
import { default as LoaderCircle } from './loader-circle'
import { default as LockLocked } from './lock-locked'
import { default as LockSharingLocked } from './lock-sharing-locked'
import { default as LockSharingUnlocked } from './lock-sharing-unlocked'
import { default as LockUnlocked } from './lock-unlocked'
import { default as MarkProcessed } from './mark-processed'
import { default as MarkUnprocessed } from './mark-unprocessed'
import { default as MeatBallsBig } from './meat-balls-big'
import { default as MeatBalls } from './meat-balls'
import { default as MediaEmbedInsertCenter } from './media-embed-insert-center'
import { default as MediaEmbedOutsetCenter } from './media-embed-outset-center'
import { default as MediaEmbedThumbnail } from './media-embed-thumbnail'
import { default as Media } from './media'
import { default as MegaSupporter } from './mega-supporter'
import { default as MergeCells } from './merge-cells'
import { default as MinimalUi } from './minimal-ui'
import { default as ModeDark } from './mode-dark'
import { default as ModeLight } from './mode-light'
import { default as MoveHorizontally } from './move-horizontally'
import { default as MoveVertically } from './move-vertically'
import { default as Move } from './move'
import { default as NotificationsUi } from './notifications-ui'
import { default as OpenAiInverted } from './open-ai-inverted'
import { default as OpenAi } from './open-ai'
import { default as PageAliasInverted } from './page-alias-inverted'
import { default as PageAliasTemplateInverted } from './page-alias-template-inverted'
import { default as PageAliasTemplate } from './page-alias-template'
import { default as PageAlias } from './page-alias'
import { default as PageHistory } from './page-history'
import { default as PageInverted } from './page-inverted'
import { default as PageLockedInverted } from './page-locked-inverted'
import { default as PageLockedTemplateInverted } from './page-locked-template-inverted'
import { default as PageLockedTemplate } from './page-locked-template'
import { default as PageLocked } from './page-locked'
import { default as PageSharedAliasInverted } from './page-shared-alias-inverted'
import { default as PageSharedAliasTemplateInverted } from './page-shared-alias-template-inverted'
import { default as PageSharedAliasTemplate } from './page-shared-alias-template'
import { default as PageSharedAlias } from './page-shared-alias'
import { default as PageSharedInverted } from './page-shared-inverted'
import { default as PageSharedLockedAliasInverted } from './page-shared-locked-alias-inverted'
import { default as PageSharedLockedAliasTemplateInverted } from './page-shared-locked-alias-template-inverted'
import { default as PageSharedLockedAliasTemplate } from './page-shared-locked-alias-template'
import { default as PageSharedLockedAlias } from './page-shared-locked-alias'
import { default as PageSharedLockedInverted } from './page-shared-locked-inverted'
import { default as PageSharedLockedTemplateInverted } from './page-shared-locked-template-inverted'
import { default as PageSharedLockedTemplate } from './page-shared-locked-template'
import { default as PageSharedLocked } from './page-shared-locked'
import { default as PageSharedTemplateInverted } from './page-shared-template-inverted'
import { default as PageSharedTemplate } from './page-shared-template'
import { default as PageShared } from './page-shared'
import { default as PageTemplateInverted } from './page-template-inverted'
import { default as PageTemplate } from './page-template'
import { default as PageWithSubpages } from './page-with-subpages'
import { default as Page } from './page'
import { default as PanelBrowserInverted } from './panel-browser-inverted'
import { default as PanelBrowser } from './panel-browser'
import { default as PanelCommandPaletteInverted } from './panel-command-palette-inverted'
import { default as PanelCommandPalette } from './panel-command-palette'
import { default as PanelCommunity } from './panel-community'
import { default as PanelFormattingInverted } from './panel-formatting-inverted'
import { default as PanelFormatting } from './panel-formatting'
import { default as PanelHashtag } from './panel-hashtag'
import { default as PanelInboxInverted } from './panel-inbox-inverted'
import { default as PanelInboxWithNotificationInverted } from './panel-inbox-with-notification-inverted'
import { default as PanelInboxWithNotification } from './panel-inbox-with-notification'
import { default as PanelInbox } from './panel-inbox'
import { default as PanelKeyboardShortcutsInverted } from './panel-keyboard-shortcuts-inverted'
import { default as PanelKeyboardShortcuts } from './panel-keyboard-shortcuts'
import { default as PanelNewsInverted } from './panel-news-inverted'
import { default as PanelNews } from './panel-news'
import { default as PanelPageDetailsInverted } from './panel-page-details-inverted'
import { default as PanelPageDetails } from './panel-page-details'
import { default as PanelSharingInverted } from './panel-sharing-inverted'
import { default as PanelSharing } from './panel-sharing'
import { default as PanelTeam } from './panel-team'
import { default as PanelTeams } from './panel-teams'
import { default as Paragraph } from './paragraph'
import { default as PlayPreviewBig } from './play-preview-big'
import { default as PlayPreviewSmall } from './play-preview-small'
import { default as PlayVideoOutline } from './play-video-outline'
import { default as PlayVideo } from './play-video'
import { default as PlusBigBold } from './plus-big-bold'
import { default as PlusBigMedium } from './plus-big-medium'
import { default as PlusBold } from './plus-bold'
import { default as PlusCircleBold } from './plus-circle-bold'
import { default as PlusCircleThin } from './plus-circle-thin'
import { default as PlusThin } from './plus-thin'
import { default as ProblemLink } from './problem-link'
import { default as ProcessWithAi } from './process-with-ai'
import { default as ProductUserInsights } from './product-user-insights'
import { default as ProjectLink } from './project-link'
import { default as Quote } from './quote'
import { default as Regex } from './regex'
import { default as Restriction } from './restriction'
import { default as RightPanelOff } from './right-panel-off'
import { default as RightPanelOn } from './right-panel-on'
import { default as Scale } from './scale'
import { default as Search } from './search'
import { default as Selection } from './selection'
import { default as SendPaperRocket } from './send-paper-rocket'
import { default as Share } from './share'
import { default as SharingAccessLink } from './sharing-access-link'
import { default as SharingAccessLock } from './sharing-access-lock'
import { default as SharingAccessTeam } from './sharing-access-team'
import { default as Smartphone1 } from './smartphone-1'
import { default as SpecificationsInverted } from './specifications-inverted'
import { default as Specifications } from './specifications'
import { default as Strikethrough } from './strikethrough'
import { default as SurveyQuestionEmail } from './survey-question-email'
import { default as SurveyQuestionMultiChoiceSingleSelect } from './survey-question-multi-choice-single-select'
import { default as SurveyQuestionName } from './survey-question-name'
import { default as SurveyQuestionNps } from './survey-question-nps'
import { default as SurveyQuestionOpenEnded } from './survey-question-open-ended'
import { default as Sync } from './sync'
import { default as TabPanelTabClose } from './tab-panel-tab-close'
import { default as TabPanelTabNew } from './tab-panel-tab-new'
import { default as TableBackspace } from './table-backspace'
import { default as TableInsertionPoint } from './table-insertion-point'
import { default as TableOfContents } from './table-of-contents'
import { default as Table } from './table'
import { default as TemplatePlaceholder } from './template-placeholder'
import { default as TickBig } from './tick-big'
import { default as TickSmall } from './tick-small'
import { default as TickTiny } from './tick-tiny'
import { default as Trash } from './trash'
import { default as Trippledot } from './trippledot'
import { default as Typing } from './typing'
import { default as Unseen } from './unseen'
import { default as UnsupportedFormat } from './unsupported-format'
import { default as Unsynced } from './unsynced'
import { default as UploadDisc } from './upload-disc'
import { default as UserFeedbackInterviewInverted } from './user-feedback-interview-inverted'
import { default as UserFeedbackInterview } from './user-feedback-interview'
import { default as UserFeedbackMessage } from './user-feedback-message'
import { default as UserFeedbackNote } from './user-feedback-note'
import { default as User } from './user'
import { default as Video } from './video'
import { default as WatchEye } from './watch-eye'
import { default as WindowsShellClose } from './windows-shell-close'
import { default as WindowsShellMaximize } from './windows-shell-maximize'
import { default as WindowsShellMinimize } from './windows-shell-minimize'
import { default as WindowsShellRestore } from './windows-shell-restore'

const icons = {
  'ai-generated-checked': AiGeneratedChecked,
  'ai-generated-flag': AiGeneratedFlag,
  'ai-generated': AiGenerated,
  'alert-info-big': AlertInfoBig,
  'alert-info-small': AlertInfoSmall,
  'alert-success-big': AlertSuccessBig,
  'alert-success-small': AlertSuccessSmall,
  'alert-warning-big': AlertWarningBig,
  'alert-warning-small': AlertWarningSmall,
  'archive-1': Archive1,
  archive: Archive,
  'arrow-alias': ArrowAlias,
  'arrow-bended-bottom-right': ArrowBendedBottomRight,
  'arrow-bended-left-up': ArrowBendedLeftUp,
  'arrow-bended-up-left': ArrowBendedUpLeft,
  'arrow-down': ArrowDown,
  'arrow-left': ArrowLeft,
  'arrow-right': ArrowRight,
  'arrow-up': ArrowUp,
  backlink: Backlink,
  'bold-italic': BoldItalic,
  bold: Bold,
  'brand-apple': BrandApple,
  'brand-chrome': BrandChrome,
  'brand-facebook': BrandFacebook,
  'brand-figma': BrandFigma,
  'brand-front': BrandFront,
  'brand-linear': BrandLinear,
  'brand-linkedin': BrandLinkedin,
  'brand-linux': BrandLinux,
  'brand-loom': BrandLoom,
  'brand-specific': BrandSpecific,
  'brand-twitter': BrandTwitter,
  'brand-windows': BrandWindows,
  'burger-big-tiny': BurgerBigTiny,
  'burger-big': BurgerBig,
  'burger-slim-2': BurgerSlim2,
  'burger-slim': BurgerSlim,
  'burger-topbar-windows': BurgerTopbarWindows,
  caption: Caption,
  'case-sensitive': CaseSensitive,
  'category-dot': CategoryDot,
  'chat-gpt-inverted': ChatGptInverted,
  'chat-gpt': ChatGpt,
  'check-circle-inverted': CheckCircleInverted,
  'check-circle': CheckCircle,
  'checkbox-tick': CheckboxTick,
  'chevron-big-left': ChevronBigLeft,
  'chevron-big-right': ChevronBigRight,
  'chevron-small-double-down': ChevronSmallDoubleDown,
  'chevron-small-double-left': ChevronSmallDoubleLeft,
  'chevron-small-double-right': ChevronSmallDoubleRight,
  'chevron-small-double-up': ChevronSmallDoubleUp,
  'chevron-small-down-bold-2': ChevronSmallDownBold2,
  'chevron-small-down-bold': ChevronSmallDownBold,
  'chevron-small-down': ChevronSmallDown,
  'chevron-small-left': ChevronSmallLeft,
  'chevron-small-right': ChevronSmallRight,
  'chevron-small-up-bold-2': ChevronSmallUpBold2,
  'chevron-small-up-bold': ChevronSmallUpBold,
  'chevron-small-up': ChevronSmallUp,
  'clear-styling': ClearStyling,
  'clear-tiny': ClearTiny,
  clear: Clear,
  'close-big': CloseBig,
  'close-tab': CloseTab,
  close: Close,
  'cloud-download': CloudDownload,
  'cloud-offline-2': CloudOffline2,
  'cloud-offline-inverted': CloudOfflineInverted,
  'cloud-offline': CloudOffline,
  'cloud-tick-2': CloudTick2,
  'cloud-tick': CloudTick,
  'cloud-upload': CloudUpload,
  'code-block': CodeBlock,
  'collapse-big-down': CollapseBigDown,
  'collapse-big-right': CollapseBigRight,
  'collapse-down': CollapseDown,
  'collapse-right': CollapseRight,
  'collapse-tiny-down': CollapseTinyDown,
  'collapse-up': CollapseUp,
  'comment-count-multi': CommentCountMulti,
  'comment-count-single': CommentCountSingle,
  'comment-count-two': CommentCountTwo,
  'comment-inverted': CommentInverted,
  comment: Comment,
  'comments-panel': CommentsPanel,
  company: Company,
  'copy-to-clipboard': CopyToClipboard,
  'description-for-ai': DescriptionForAi,
  'discussion-accept': DiscussionAccept,
  'discussion-reject': DiscussionReject,
  'discussion-resolve': DiscussionResolve,
  'download-1': Download1,
  'download-2': Download2,
  drag: Drag,
  'dropdown-down': DropdownDown,
  'dropdown-right': DropdownRight,
  'dropdown-select-box': DropdownSelectBox,
  'embed-open-in-browser': EmbedOpenInBrowser,
  'embed-play': EmbedPlay,
  'embed-zoom-in': EmbedZoomIn,
  'error-exclamation': ErrorExclamation,
  'exclamation-circle': ExclamationCircle,
  'exclamation-triangle-inverted': ExclamationTriangleInverted,
  'exclamation-triangle': ExclamationTriangle,
  export: Export,
  'external-url': ExternalUrl,
  'eye-crossed': EyeCrossed,
  'feature-link': FeatureLink,
  'files-customers': FilesCustomers,
  'files-dashboard': FilesDashboard,
  'files-events': FilesEvents,
  'files-fields': FilesFields,
  'files-getting-started': FilesGettingStarted,
  'files-import-data': FilesImportData,
  'files-inbox': FilesInbox,
  'files-input-request-v2': FilesInputRequestV2,
  'files-input-request': FilesInputRequest,
  'files-integrations-puzzle': FilesIntegrationsPuzzle,
  'files-notifications-inbox': FilesNotificationsInbox,
  'files-overview': FilesOverview,
  'files-problems-v2': FilesProblemsV2,
  'files-problems': FilesProblems,
  'files-review-requests-v2': FilesReviewRequestsV2,
  'files-review-requests': FilesReviewRequests,
  'files-roadmap': FilesRoadmap,
  'files-sources-inverted': FilesSourcesInverted,
  'files-sources': FilesSources,
  'files-studies': FilesStudies,
  'files-survey-inverted': FilesSurveyInverted,
  'files-survey-widget': FilesSurveyWidget,
  'files-survey': FilesSurvey,
  'files-tasks': FilesTasks,
  'files-topics': FilesTopics,
  'files-user-insights-v2': FilesUserInsightsV2,
  'files-user-insights': FilesUserInsights,
  filter: Filter,
  'folder-alias-inverted': FolderAliasInverted,
  'folder-alias': FolderAlias,
  'folder-bookmarks-inverted': FolderBookmarksInverted,
  'folder-bookmarks': FolderBookmarks,
  'folder-drafts-inverted': FolderDraftsInverted,
  'folder-drafts': FolderDrafts,
  'folder-inverted': FolderInverted,
  'folder-locked-inverted': FolderLockedInverted,
  'folder-locked': FolderLocked,
  'folder-missing-parent-inverted': FolderMissingParentInverted,
  'folder-missing-parent': FolderMissingParent,
  'folder-shared-alias-inverted': FolderSharedAliasInverted,
  'folder-shared-alias': FolderSharedAlias,
  'folder-shared-inverted': FolderSharedInverted,
  'folder-shared-locked-alias-inverted': FolderSharedLockedAliasInverted,
  'folder-shared-locked-alias': FolderSharedLockedAlias,
  'folder-shared-locked-inverted': FolderSharedLockedInverted,
  'folder-shared-locked': FolderSharedLocked,
  'folder-shared': FolderShared,
  'folder-smart-inverted': FolderSmartInverted,
  'folder-smart': FolderSmart,
  'folder-specs-inverted': FolderSpecsInverted,
  'folder-specs': FolderSpecs,
  folder: Folder,
  formatting: Formatting,
  'full-screen': FullScreen,
  gear: Gear,
  'globe-1': Globe1,
  'globe-locked': GlobeLocked,
  globe: Globe,
  hashtag: Hashtag,
  heading: Heading,
  'help-panel-2': HelpPanel2,
  'help-panel-inverted': HelpPanelInverted,
  'help-panel': HelpPanel,
  'hidden-comment': HiddenComment,
  'hide-panel-arrow-left': HidePanelArrowLeft,
  'hide-panel-arrow-right': HidePanelArrowRight,
  highlight: Highlight,
  'horizontal-rule': HorizontalRule,
  image: Image,
  import: Import,
  'info-circle': InfoCircle,
  info: Info,
  'inline-code': InlineCode,
  'input-field-error': InputFieldError,
  'input-field-success': InputFieldSuccess,
  'insight-all': InsightAll,
  'insight-assigned': InsightAssigned,
  'insight-inverted': InsightInverted,
  'insight-unassigned': InsightUnassigned,
  'insight-unprocessed': InsightUnprocessed,
  insight: Insight,
  italic: Italic,
  'item-feature-counter': ItemFeatureCounter,
  'item-feature-inverted': ItemFeatureInverted,
  'item-feature-set-inverted': ItemFeatureSetInverted,
  'item-feature-set': ItemFeatureSet,
  'item-feature-template-v2': ItemFeatureTemplateV2,
  'item-feature-template': ItemFeatureTemplate,
  'item-feature': ItemFeature,
  'item-problem-inverted': ItemProblemInverted,
  'item-problem-v2': ItemProblemV2,
  'item-problem': ItemProblem,
  'item-project-counter': ItemProjectCounter,
  'item-project-inverted': ItemProjectInverted,
  'item-project': ItemProject,
  'item-tag-add': ItemTagAdd,
  'item-tag-inverted': ItemTagInverted,
  'item-tag': ItemTag,
  'kebab-big': KebabBig,
  'kebab-small': KebabSmall,
  'lead-unassigned': LeadUnassigned,
  'link-bold': LinkBold,
  link: Link,
  'list-bullet': ListBullet,
  'list-check': ListCheck,
  'list-indent-left': ListIndentLeft,
  'list-indent-right': ListIndentRight,
  'list-ordered': ListOrdered,
  'list-unordered': ListUnordered,
  list: List,
  'loader-circle-tiny': LoaderCircleTiny,
  'loader-circle': LoaderCircle,
  'lock-locked': LockLocked,
  'lock-sharing-locked': LockSharingLocked,
  'lock-sharing-unlocked': LockSharingUnlocked,
  'lock-unlocked': LockUnlocked,
  'mark-processed': MarkProcessed,
  'mark-unprocessed': MarkUnprocessed,
  'meat-balls-big': MeatBallsBig,
  'meat-balls': MeatBalls,
  'media-embed-insert-center': MediaEmbedInsertCenter,
  'media-embed-outset-center': MediaEmbedOutsetCenter,
  'media-embed-thumbnail': MediaEmbedThumbnail,
  media: Media,
  'mega-supporter': MegaSupporter,
  'merge-cells': MergeCells,
  'minimal-ui': MinimalUi,
  'mode-dark': ModeDark,
  'mode-light': ModeLight,
  'move-horizontally': MoveHorizontally,
  'move-vertically': MoveVertically,
  move: Move,
  'notifications-ui': NotificationsUi,
  'open-ai-inverted': OpenAiInverted,
  'open-ai': OpenAi,
  'page-alias-inverted': PageAliasInverted,
  'page-alias-template-inverted': PageAliasTemplateInverted,
  'page-alias-template': PageAliasTemplate,
  'page-alias': PageAlias,
  'page-history': PageHistory,
  'page-inverted': PageInverted,
  'page-locked-inverted': PageLockedInverted,
  'page-locked-template-inverted': PageLockedTemplateInverted,
  'page-locked-template': PageLockedTemplate,
  'page-locked': PageLocked,
  'page-shared-alias-inverted': PageSharedAliasInverted,
  'page-shared-alias-template-inverted': PageSharedAliasTemplateInverted,
  'page-shared-alias-template': PageSharedAliasTemplate,
  'page-shared-alias': PageSharedAlias,
  'page-shared-inverted': PageSharedInverted,
  'page-shared-locked-alias-inverted': PageSharedLockedAliasInverted,
  'page-shared-locked-alias-template-inverted':
    PageSharedLockedAliasTemplateInverted,
  'page-shared-locked-alias-template': PageSharedLockedAliasTemplate,
  'page-shared-locked-alias': PageSharedLockedAlias,
  'page-shared-locked-inverted': PageSharedLockedInverted,
  'page-shared-locked-template-inverted': PageSharedLockedTemplateInverted,
  'page-shared-locked-template': PageSharedLockedTemplate,
  'page-shared-locked': PageSharedLocked,
  'page-shared-template-inverted': PageSharedTemplateInverted,
  'page-shared-template': PageSharedTemplate,
  'page-shared': PageShared,
  'page-template-inverted': PageTemplateInverted,
  'page-template': PageTemplate,
  'page-with-subpages': PageWithSubpages,
  page: Page,
  'panel-browser-inverted': PanelBrowserInverted,
  'panel-browser': PanelBrowser,
  'panel-command-palette-inverted': PanelCommandPaletteInverted,
  'panel-command-palette': PanelCommandPalette,
  'panel-community': PanelCommunity,
  'panel-formatting-inverted': PanelFormattingInverted,
  'panel-formatting': PanelFormatting,
  'panel-hashtag': PanelHashtag,
  'panel-inbox-inverted': PanelInboxInverted,
  'panel-inbox-with-notification-inverted': PanelInboxWithNotificationInverted,
  'panel-inbox-with-notification': PanelInboxWithNotification,
  'panel-inbox': PanelInbox,
  'panel-keyboard-shortcuts-inverted': PanelKeyboardShortcutsInverted,
  'panel-keyboard-shortcuts': PanelKeyboardShortcuts,
  'panel-news-inverted': PanelNewsInverted,
  'panel-news': PanelNews,
  'panel-page-details-inverted': PanelPageDetailsInverted,
  'panel-page-details': PanelPageDetails,
  'panel-sharing-inverted': PanelSharingInverted,
  'panel-sharing': PanelSharing,
  'panel-team': PanelTeam,
  'panel-teams': PanelTeams,
  paragraph: Paragraph,
  'play-preview-big': PlayPreviewBig,
  'play-preview-small': PlayPreviewSmall,
  'play-video-outline': PlayVideoOutline,
  'play-video': PlayVideo,
  'plus-big-bold': PlusBigBold,
  'plus-big-medium': PlusBigMedium,
  'plus-bold': PlusBold,
  'plus-circle-bold': PlusCircleBold,
  'plus-circle-thin': PlusCircleThin,
  'plus-thin': PlusThin,
  'problem-link': ProblemLink,
  'process-with-ai': ProcessWithAi,
  'product-user-insights': ProductUserInsights,
  'project-link': ProjectLink,
  quote: Quote,
  regex: Regex,
  restriction: Restriction,
  'right-panel-off': RightPanelOff,
  'right-panel-on': RightPanelOn,
  scale: Scale,
  search: Search,
  selection: Selection,
  'send-paper-rocket': SendPaperRocket,
  share: Share,
  'sharing-access-link': SharingAccessLink,
  'sharing-access-lock': SharingAccessLock,
  'sharing-access-team': SharingAccessTeam,
  'smartphone-1': Smartphone1,
  'specifications-inverted': SpecificationsInverted,
  specifications: Specifications,
  strikethrough: Strikethrough,
  'survey-question-email': SurveyQuestionEmail,
  'survey-question-multi-choice-single-select':
    SurveyQuestionMultiChoiceSingleSelect,
  'survey-question-name': SurveyQuestionName,
  'survey-question-nps': SurveyQuestionNps,
  'survey-question-open-ended': SurveyQuestionOpenEnded,
  sync: Sync,
  'tab-panel-tab-close': TabPanelTabClose,
  'tab-panel-tab-new': TabPanelTabNew,
  'table-backspace': TableBackspace,
  'table-insertion-point': TableInsertionPoint,
  'table-of-contents': TableOfContents,
  table: Table,
  'template-placeholder': TemplatePlaceholder,
  'tick-big': TickBig,
  'tick-small': TickSmall,
  'tick-tiny': TickTiny,
  trash: Trash,
  trippledot: Trippledot,
  typing: Typing,
  unseen: Unseen,
  'unsupported-format': UnsupportedFormat,
  unsynced: Unsynced,
  'upload-disc': UploadDisc,
  'user-feedback-interview-inverted': UserFeedbackInterviewInverted,
  'user-feedback-interview': UserFeedbackInterview,
  'user-feedback-message': UserFeedbackMessage,
  'user-feedback-note': UserFeedbackNote,
  user: User,
  video: Video,
  'watch-eye': WatchEye,
  'windows-shell-close': WindowsShellClose,
  'windows-shell-maximize': WindowsShellMaximize,
  'windows-shell-minimize': WindowsShellMinimize,
  'windows-shell-restore': WindowsShellRestore,
}

export type IconNames = keyof typeof icons
export default icons
